import React from 'react';

import classnames from 'classnames/bind';

import StickyHeader from '@COMPONENTS/HEADERS/StickyHeader';
import PairToggleButtons from '@COMPONENTS/COMMON/buttons/PairToggleButtons';

import styles from './SubSectionStickyHeader.module.scss';

const cx: CX = classnames.bind(styles);

function SubSectionStickHeader(props: any) {
    const {
        section,
        index,
        selectedSubsectionIds,
        setSelectedSubsectionIds,
        isSubSection,
    } = props;

    const selectAll = () => {
        const allChildrenIds = section.children?.map((child: any) => child.id) || [];
        const updatedSet = new Set(selectedSubsectionIds);
        allChildrenIds.forEach((id: number) => updatedSet.add(id));
        setSelectedSubsectionIds(updatedSet);
    };

    const deselectAll = () => {
        const allChildrenIds = section.children?.map((child: any) => child.id) || [];
        const updatedSet = new Set(selectedSubsectionIds);
        allChildrenIds.forEach((id: number) => updatedSet.delete(id));
        setSelectedSubsectionIds(updatedSet);
    };

    return (
        <StickyHeader top={80}>
            <div className={cx('chapter-sticky-header', 'container')}>
                <div className={cx('chapter-title')}>
                    {`${isSubSection ? 'Sub Section' : 'Sub Sub Section'} ${index + 1} - ${section.name}`}
                </div>
                <div className={cx('selectors')}>
                    <PairToggleButtons
                        titleOn="Select all"
                        titleOff="Deselect all"
                        onClickOn={selectAll}
                        onClickOff={deselectAll}
                    />
                </div>
            </div>
        </StickyHeader>
    );
}

export default SubSectionStickHeader;
