import { ADMIN_API_PREFIX, BASE_URL } from '@CONSTANTS/API.constant';
import { AdminSingleChapterDataSection } from '@INTERFACES/admin-api/chapters';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import userRepository from '@REPOSITORIES/local-repository/user-repository';
import {
    AdminSingleDocumentChapter, chaptersAdminApi, documentsAdminApi, sectionsAdminApi, unpublishedAdminApi,
} from '@SERVICES';
import invalidateData from '../helpers/invalidateData';

export type ReorderChaptersParams = {
    documentId: number;
    chapters: {
        id: number;
        order: number;
    }[];
};

export type ReorderSectionsParams = {
    documentId: number;
    chapterId: number;
    sections: {
        id: number;
        order: number;
    }[];
};

export const reorderAdminApi = createApi({
    reducerPath: 'reorder-admin-api',
    baseQuery: fetchBaseQuery({
        baseUrl: BASE_URL + ADMIN_API_PREFIX,
        prepareHeaders: (headers) => {
            const token = userRepository.getData();

            if (token) {
                headers.set('Authorization', `Bearer ${token}`);
            }

            return headers;
        },
    }),
    endpoints: (builder) => ({
        reorderChapters: builder.mutation({
            query: (params: ReorderChaptersParams) => {
                const formData = JSON.stringify(
                    [
                        {
                            chapter: params.chapters,
                        },
                    ],
                );

                return ({
                    url: '/order/',
                    method: 'POST',
                    body: formData,
                });
            },
            async onQueryStarted({ documentId, chapters }, { dispatch, queryFulfilled }) {
                try {
                    await queryFulfilled;

                    // INVALIDATE DATA
                    invalidateData(dispatch);

                    dispatch(
                        unpublishedAdminApi.util.resetApiState(),
                    );

                    // UPDATE CHAPTERS ORDER IN DOCUMENTS LIST
                    dispatch(
                        documentsAdminApi.util.updateQueryData('getAll', undefined, (draft) => draft.map((doc) => {
                            if (doc.data.id === documentId) {
                                return {
                                    data: {
                                        ...doc.data,
                                        chapters: doc.data.chapters.map((chapter) => {
                                            const foundChapter = chapters.find((c) => c.id === chapter.id);

                                            return ({
                                                ...chapter,
                                                order: foundChapter!.order,
                                            });
                                        }).sort((a, b) => a.order - b.order),
                                    },
                                    meta: doc.meta,
                                };
                            }

                            return doc;
                        })),
                    );

                    // UPDATE CHAPTERS ORDER IN SINGLE DOCUMENT
                    const patchDocument = dispatch(
                        documentsAdminApi.util.updateQueryData('getById', documentId, (draft) => ({
                            data: {
                                ...draft.data,
                                chapters: draft.data.chapters.map((chapter) => {
                                    const foundChapter = chapters.find((c) => c.id === chapter.id);

                                    return ({
                                        ...chapter,
                                        order: foundChapter!.order,
                                    });
                                }).sort((a, b) => a.order - b.order),
                            },
                            meta: draft.meta,
                        })),
                    );

                    const data = patchDocument.patches[0]?.value;

                    if (data) {
                        // UPDATE CHAPTERS ORDER IN EVERY SINGLE CHAPTER META
                        data.data.chapters.forEach((chapter: AdminSingleDocumentChapter) => {
                            dispatch(
                                chaptersAdminApi.util.updateQueryData('getById', chapter.id, (draft) => ({
                                    data: draft.data,
                                    meta: {
                                        ...draft.meta,
                                        chapters: draft.meta.chapters.map((c) => {
                                            const foundChapter = chapters.find((ch) => ch.id === c.id);

                                            return {
                                                ...c,
                                                order: foundChapter!.order,
                                            };
                                        }).sort((a, b) => a.order - b.order),
                                    },
                                })),
                            );
                        });
                    }
                } catch (e) {
                    //
                }
            },
        }),
        reorderSections: builder.mutation({
            query: (params: ReorderSectionsParams) => {
                const formData = JSON.stringify(
                    [
                        {
                            section: params.sections,
                        },
                    ],
                );

                return ({
                    url: '/order/',
                    method: 'POST',
                    body: formData,
                });
            },
            async onQueryStarted({ documentId, chapterId, sections }, { dispatch, queryFulfilled }) {
                try {
                    await queryFulfilled;

                    // INVALIDATE DATA
                    invalidateData(dispatch);

                    dispatch(
                        unpublishedAdminApi.util.resetApiState(),
                    );

                    // UPDATE SECTIONS ORDER IN SINGLE DOCUMENT
                    dispatch(
                        documentsAdminApi.util.updateQueryData('getById', documentId, (draft) => {
                            // Recursive function to update sections
                            const updateSections = (draftSections: any[]): any[] => draftSections
                                .map((section: any): any => {
                                    const foundSection = sections.find((s) => s.id === section.id);

                                    const updatedSection = {
                                        ...section,
                                        ...(foundSection ? { order: foundSection.order } : {}),
                                        children: section.children ? updateSections(section.children) : [],
                                    };

                                    return updatedSection;
                                })
                                .sort((a, b) => a.order - b.order);

                            return {
                                data: {
                                    ...draft.data,
                                    chapters: draft.data.chapters.map((chapter) => {
                                        if (chapterId === chapter.id) {
                                            return {
                                                ...chapter,
                                                sections: updateSections(chapter.sections),
                                            };
                                        }

                                        return chapter;
                                    }),
                                },
                                meta: draft.meta,
                            };
                        }),
                    );

                    // UPDATE SECTIONS ORDER IN SINGLE CHAPTER
                    const chapterPatch = dispatch(
                        chaptersAdminApi.util.updateQueryData('getById', chapterId, (draft) => {
                            const updateSections = (draftSections: any[]) => draftSections.map((section: any): any => {
                                const foundSection = sections.find((s) => s.id === section.id);

                                const updatedSection = {
                                    ...section,
                                    ...(foundSection ? { order: foundSection.order } : {}),
                                    children: section.children ? updateSections(section.children) : [],
                                };

                                return updatedSection;
                            }).sort((a, b) => a.order - b.order);

                            return {
                                data: {
                                    ...draft.data,
                                    sections: updateSections(draft.data.sections),
                                },
                                meta: draft.meta,
                            };
                        }),
                    );

                    const data = chapterPatch.patches[0]?.value;

                    if (data) {
                        // UPDATE SECTIONS ORDER IN EVERY SINGLE SECTION META
                        const updateSections = (draftSections: any[], sourceSections: any[]) => draftSections
                            .map((section: any): any => {
                                const foundSection = sourceSections.find((s) => s.id === section.id);

                                return {
                                    ...section,
                                    ...(foundSection ? { order: foundSection.order } : {}),
                                    children: section.children ? updateSections(section.children, sourceSections) : [],
                                };
                            })
                            .sort((a, b) => a.order - b.order);
                        data.data.sections.forEach((section: AdminSingleChapterDataSection) => {
                            dispatch(
                                sectionsAdminApi.util.updateQueryData('getById', section.id, (draft) => ({
                                    data: draft.data,
                                    meta: {
                                        ...draft.meta,
                                        sections: updateSections(draft.meta.sections, sections),
                                    },
                                })),
                            );
                        });
                    }
                } catch (e) {
                    //
                }
            },
        }),
    }),
});

export const {
    useReorderChaptersMutation: useReorderChapters,
    useReorderSectionsMutation: useReorderSections,
} = reorderAdminApi;
