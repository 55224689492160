import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import classnames from 'classnames/bind';

import { AdminSingleDocumentMetaDocument } from '@SERVICES';

import Accordions from '@COMPONENTS/COMMON/accordion/accordion';
import useDocumentPermissions from '@HOOKS/useDocumentPermissions';

import ROUTES from '@CONSTANTS/ROUTES.constant';

import styles from './DocumentSidebar.module.scss';
import ChapterSideBarTreeView from '../SideBarTreeview';
import AddSectionButton from './AddSectionButton.component';

const cx: CX = classnames.bind(styles);

function DocumentSidebar(props: Props) {
    const {
        documents, chapters, permission, document,
    } = props;
    const navigate = useNavigate();

    const { canEditDocument } = useDocumentPermissions({
        documentOwnerId: document.documentOwnerId,
        permissions: permission,
    });

    const { id } = useParams();
    const activeDocument = documents.find(
        ({ id: docId }) => docId === Number(id),
    );

    const addSubSectionButton = (
        btnText: string,
        chId: number,
        parentId: number | null = null,
    ) => (
        <AddSectionButton
            locked={!canEditDocument}
            title={btnText}
            payload={{
                documentId: document.documentId,
                chapterId: chId,
                parentId,
            }}
        />
    );

    const handleNavigate = (navigateId: number, navigateType = 'sections') => {
        navigate(
            (ROUTES.admin[navigateType as keyof typeof ROUTES.admin] as { [key: string]: any }).id.get(navigateId),
        );
    };

    return (
        <div className={cx('document-sidebar')}>
            <div className={cx('document-view')}>
                <div className={cx('documents-title')}>
                    {activeDocument?.name}
                </div>
                <div className={cx('documents-list')}>
                    {chapters?.map(({ id: chId, name, sections }) => (
                        <Accordions
                            key={chId}
                            title={name}
                            detailsContain={(
                                <ChapterSideBarTreeView
                                    list={sections}
                                    chapterId={chId}
                                    addSubSectionButton={addSubSectionButton}
                                    handleNavigate={handleNavigate}
                                />
                            )}
                            handleClick={() => handleNavigate(chId, 'chapters')}
                        />
                    ))}
                </div>
            </div>
        </div>
    );
}

type Props = {
    documents: AdminSingleDocumentMetaDocument[];
    chapters: any[];
    permission: any[];
    document: {
        documentOwnerId: number;
        documentId: number;
    };
};

export default DocumentSidebar;
