import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { API_PREFIX, BASE_URL } from '@CONSTANTS/API.constant';
import {
    PreviewChapter, Preview,
} from '@INTERFACES/api/preview';
import userRepository from '@REPOSITORIES/local-repository/user-repository';

type Section = {
    id: number;
    name: string;
    order: number;
    has_body: boolean;
    is_mandatory: boolean;
    is_landscape: boolean;
    body: string | null;
    variant: {
        id: number;
        name: string;
        is_default: boolean;
        has_body: boolean;
        body: string;
    } | null;
    children: Section[] | []
};

type PreviewApiResponse = {
    data: {
        id: number;
        name: string;
        cover: string | null;
        chapters: {
            id: number;
            name: string;
            order: number;
            is_mandatory: boolean;
            image: string | null;
            body: string | null;
            has_body: boolean;
            sections: Section[];
        }[];
    };
};

const SortByOrder = (a: any, b: any) => a.order - b.order;

function transformSection(section: any): any {
    return {
        id: section.id,
        name: section.name,
        order: section.order,
        hasBody: section.has_body,
        isMandatory: section.is_mandatory,
        isLandscape: section.is_landscape,
        body: section.body,
        variant: section.variant ? {
            id: section.variant.id,
            name: section.variant.name,
            isDefault: section.variant.is_default,
            hasBody: section.variant.has_body,
            body: section.variant.body,
        } : null,
        children: section.children ? section.children.map(transformSection) : [],
    };
}

function transformPreviewResponse(raw: PreviewApiResponse): Preview {
    const data: PreviewChapter[] = raw.data.chapters.map((chapter) => ({
        id: chapter.id,
        name: chapter.name,
        order: chapter.order,
        isMandatory: chapter.is_mandatory,
        image: chapter.image,
        body: chapter.body,
        hasBody: chapter.has_body,
        sections: chapter.sections.map(transformSection),
    }));

    data.sort(SortByOrder).map((c) => c.sections.sort(SortByOrder));

    return {
        data,
        meta: {
            document: {
                id: raw.data.id,
                name: raw.data.name,
                cover: raw.data.cover,
            },
        },
    };
}

type Id = number | undefined;

type GetPreviewParams = {
    documentId: Id;

    variant: number[];

    section: number[];

    chapter: number[];

    brand: Id;

    country: Id;

    type: Id;
};

export const previewApi = createApi({
    reducerPath: 'preview-api',
    baseQuery: fetchBaseQuery({
        baseUrl: BASE_URL + API_PREFIX,
        prepareHeaders: (headers) => {
            const token = userRepository.getData();

            if (token) {
                headers.set('Authorization', `Bearer ${token}`);
            }

            return headers;
        },
    }),
    endpoints: (builder) => ({
        getPreview: builder.query<Preview, GetPreviewParams>({
            query: (params: GetPreviewParams) => ({
                url: `/preview/${params.documentId}/`,
                params: {
                    variant: params.variant.join(','),
                    section: params.section.join(','),
                    chapter: params.chapter.join(','),
                    brand: params.brand,
                    country: params.country,
                    property_type: params.type,
                },
            }),
            transformResponse(raw: PreviewApiResponse) {
                return transformPreviewResponse(raw);
            },
        }),
    }),
});

export const {
    useGetPreviewQuery: useGetPreview,
} = previewApi;
