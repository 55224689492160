import React from 'react';
import Accordions from '@COMPONENTS/COMMON/accordion/accordion';
import './ChapterSideBarTreeView.module.scss';

function SectionSubMenu(props: any) {
    const {
        list, addSubSectionButton, chapterId, parentId, handleNavigate, hideButton,
    } = props;

    return (
        <ul>
            {list?.map((item: any) => {
                const content = (
                    <li key={item.id} style={{}}>
                        {item.name}
                    </li>
                );
                return (
                    <Accordions
                        key={item.id}
                        title={item.name}
                        detailsContain={content}
                        handleClick={() => {
                            handleNavigate(item.id);
                        }}
                        classNames="remove-icon"
                    />
                );
            })}
            {!hideButton && addSubSectionButton('Add Sub Sub-Section', chapterId, parentId)}
        </ul>
    );
}

export default SectionSubMenu;
