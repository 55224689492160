import React from 'react';
import PropTypes, { InferProps } from 'prop-types';

import classnames from 'classnames/bind';

import { PreviewChapter } from '@INTERFACES/api/preview';

import Section from '@PAGES/manager/preview/components/Section';
import ChapterIntroductionText from '@PAGES/manager/preview/components/ChapterIntroductionText';
import StickyHeader from '@COMPONENTS/HEADERS/StickyHeader';

import styles from './Chapter.module.scss';

const cx: CX = classnames.bind(styles);

function Chapter(props: Props) {
    const { top, isPartial, chapter } = props;

    const renderSectionView = (data: any[], depth = 1) => (
        data?.map((section) => (
            <div key={section.id} className={cx('section-wrapper')}>
                <Section
                    section={section}
                    isChapterMandatory={chapter.isMandatory}
                    isPartial={isPartial}
                />
                {section.children?.length > 0
                            && renderSectionView(section.children, depth + 1)}
            </div>
        ))
    );

    return (
        <div className={cx('chapter')}>
            <div id={`chapter-${chapter.id}`} className={cx('anchor')} />
            <StickyHeader top={top}>
                <div className={cx('chapters-sticky-header', 'container')}>
                    <div className={cx('title')}>{chapter.name}</div>
                </div>
            </StickyHeader>
            <div className={cx('chapter-body')}>
                {chapter.hasBody && (
                    <div className={cx('chapter-introduction-text-wrapper')}>
                        <div
                            id={`introduction-text-chapter-${chapter.id}`}
                            className={cx('anchor')}
                        />
                        <ChapterIntroductionText body={chapter.body || ''} />
                    </div>
                )}
                <div className={cx('sections')}>
                    {renderSectionView(chapter.sections)}
                </div>
            </div>
        </div>
    );
}

Chapter.propTypes = {
    top: PropTypes.number.isRequired,
    isPartial: PropTypes.bool.isRequired,
};

type Props = InferProps<typeof Chapter.propTypes> & {
    chapter: PreviewChapter;
};

export default Chapter;
