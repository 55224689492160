import React from 'react';

import classnames from 'classnames/bind';

import { PreviewData } from '@INTERFACES/api/preview';

import styles from './PreviewSidebar.module.scss';

const cx: CX = classnames.bind(styles);

type SectionItem = {
    id: number;
    name: string;
};

type SectionResult = {
    Section: SectionItem[];
    'Sub Section': SectionItem[];
    'Sub Sub Section': SectionItem[];
};

function PreviewSidebar(props: Props) {
    const { previewData } = props;

    function handleChapterClick(id: number) {
        const anchor = `chapter-${id}`;

        const anchorEl = document.getElementById(anchor);

        if (anchorEl) {
            anchorEl.scrollIntoView();
        }
    }

    function handleIntroductionTextClick(id: number) {
        const anchor = `introduction-text-chapter-${id}`;

        const anchorEl = document.getElementById(anchor);

        if (anchorEl) {
            anchorEl.scrollIntoView();
        }
    }

    function handleSectionClick(id: number) {
        const anchor = `section-${id}`;

        const anchorEl = document.getElementById(anchor);

        if (anchorEl) {
            anchorEl.scrollIntoView();
        }
    }

    const sectionView = (result: SectionResult) => Object.keys(result).map((o) => {
        const displayValues = result[o as keyof SectionResult];
        if (!displayValues || displayValues.length === 0) {
            return null;
        }
        return (
            <>
                <div className={cx('sections-title')}>{o}</div>
                <div className={cx('sections-list')}>
                    {displayValues.map((section) => {
                        const sectionKey = section.id;
                        return (
                            <div key={sectionKey} className={cx('section')}>
                                <button
                                    type="button"
                                    className={cx('link', 'clear-button')}
                                    onClick={() => {
                                        handleSectionClick(section.id);
                                    }}
                                >
                                    {section.name}
                                </button>
                            </div>
                        );
                    })}
                </div>
            </>
        );
    });

    return (
        <div className={cx('preview-sidebar')}>
            <div className="container-left">
                <div className={cx('chapters-title')}>
                    Chapters
                </div>
                <div className={cx('chapters-list')}>
                    {
                        previewData.map((chapter) => {
                            const chapterKey = chapter.id;
                            const result = chapter.sections.reduce<SectionResult>(
                                (acc, curSec) => {
                                    const { children: subSections = [] } = curSec;

                                    // Map sub-sections
                                    const subSectionItems = subSections.map((subSec) => ({
                                        id: subSec.id,
                                        name: subSec.name,
                                    }));

                                    // Map sub-sub-sections
                                    const subsubSectionItems = subSections.flatMap((subSec) => (subSec.children || []).map((subsubSec) => ({
                                        id: subsubSec.id,
                                        name: subsubSec.name,
                                    })));

                                    // Return updated accumulator with the correct keys
                                    return {
                                        Section: [...acc.Section, { id: curSec.id, name: curSec.name }],
                                        'Sub Section': [...acc['Sub Section'], ...subSectionItems],
                                        'Sub Sub Section': [...acc['Sub Sub Section'], ...subsubSectionItems],
                                    };
                                },
                                { Section: [], 'Sub Section': [], 'Sub Sub Section': [] }, // Initial accumulator
                            );

                            return (
                                <div
                                    key={chapterKey}
                                    className={cx('chapter-list-item')}
                                >
                                    <div className={cx('chapter')}>
                                        <button
                                            type="button"
                                            className={cx('link', 'clear-button')}
                                            onClick={() => {
                                                handleChapterClick(chapter.id);
                                            }}
                                        >
                                            {chapter.name}
                                        </button>
                                    </div>
                                    {
                                        chapter.hasBody
                                        && (
                                            <div className={cx('introduction-text')}>
                                                <button
                                                    type="button"
                                                    className={cx('link', 'clear-button')}
                                                    onClick={() => {
                                                        handleIntroductionTextClick(chapter.id);
                                                    }}
                                                >
                                                    Introduction text
                                                </button>
                                            </div>
                                        )
                                    }
                                    {sectionView(result)}
                                </div>
                            );
                        })
                    }
                </div>
            </div>
        </div>
    );
}

type Props = {
    previewData: PreviewData,
};

export default PreviewSidebar;
