import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import React, { useState } from 'react';

import classnames from 'classnames/bind';

import { Button, Typography } from '@mui/material';
import styles from './accordion.module.scss';

const cx: CX = classnames.bind(styles);

function Accordions(props: AccordionsProps) {
    const {
        title,
        detailsContain,
        defaultExpanded = false,
        classNames,
        handleClick,
        headerSection,
        icon,
    } = props;
    const [isExpanded, setIsExpanded] = useState(defaultExpanded);

    const Icon = (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
            <path d="M310.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-192 192c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L242.7 256 73.4 86.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l192 192z" />
        </svg>
    );
    const handleButtonClick = () => {
        if (handleClick) {
            handleClick();
        }
    };

    const handleChange = (_event: React.SyntheticEvent, expanded: boolean) => {
        if (classNames === 'remove-icon') return;
        setIsExpanded(expanded);
    };

    return (
        <div className={cx('accordion-wrapper')}>
            <Accordion
                defaultExpanded={defaultExpanded}
                expanded={isExpanded}
                onChange={handleChange}
            >
                <AccordionSummary
                    className={cx(
                        'accordion-summary',
                        classNames,
                        'custom-accordion-summary',
                    )}
                    expandIcon={Icon}
                    aria-controls="panel1-content"
                    id="panel1-header"
                >
                    <Typography className={cx('accordion-title-wrapper')}>
                        <Button
                            variant="text"
                            className={cx('accordion-title')}
                            onClick={handleButtonClick}
                        >
                            {title}
                        </Button>
                        {icon}
                    </Typography>

                    {headerSection}
                </AccordionSummary>
                <AccordionDetails
                    className={cx(
                        'accordion-details',
                        'custom-accordion-details',
                    )}
                >
                    {detailsContain}
                </AccordionDetails>
            </Accordion>
        </div>
    );
}

type AccordionsProps = {
    title: string;
    detailsContain: React.ReactNode;
    defaultExpanded?: boolean;
    classNames?: string;
    handleClick?: () => void;
    headerSection?: React.ReactNode;
    icon?: React.ReactNode;
};

Accordions.defaultProps = {
    defaultExpanded: false,
    classNames: '',
    handleClick: () => {},
    headerSection: null,
    icon: null,
};

export default Accordions;
