import DragAndDropList from '@COMPONENTS/SHARED/DragAndDropList';
import classNames from 'classnames/bind';
import React from 'react';
import styles from './RecursiveDragAndDrop.module.scss';

const cx: CX = classNames.bind(styles);

interface TreeNode {
    id: number;
    name: string;
    section?: TreeNode[];
    subSection?: TreeNode[];
    subMenu?: TreeNode[];
}

interface Props {
    treeData: TreeNode[];
    onDragEnd: (items: TreeNode[], level: string, parentId?: number) => void;
}

function RecursiveDragAndDrop(props: Props) {
    const { treeData, onDragEnd } = props;

    const renderDragAndDrop = (
        list: TreeNode[],
        level: string,
        parentId?: number,
        hierarchyPrefix = '',
    ) => (
        <ul className={cx('tree-list', `${level}-list`)}>
            <DragAndDropList<TreeNode>
                droppableId={`${level}-${parentId || 'root'}`}
                list={list}
                onDragEnd={(items) => onDragEnd(items, level, parentId)}
            >
                {(item, index, dragId) => {
                    const currentIndex = `${hierarchyPrefix}${index + 1}`;
                    return (
                        <li
                            key={item.id}
                            className={cx('tree-item', `${level}-item`, {
                                dragged: item.id === dragId,
                            })}
                        >
                            <div className={cx('item-content')}>
                                <span className={cx('item-number')}>{currentIndex}</span>
                                <span className={cx('item-name')}>{item.name}</span>
                            </div>

                            {item.section?.length > 0 && (
                                <li className={cx('section-wrapper')}>
                                    {renderDragAndDrop(
                                        item.section,
                                        'section',
                                        item.id,
                                        `${currentIndex}.`, // Pass down hierarchy prefix
                                    )}
                                </li>
                            )}

                            {item.subSection?.length > 0 && (
                                <li className={cx('subsection-wrapper')}>
                                    {renderDragAndDrop(
                                        item.subSection,
                                        'subSection',
                                        item.id,
                                        `${currentIndex}.`, // Pass down hierarchy prefix
                                    )}
                                </li>
                            )}

                            {item.subMenu?.length > 0 && (
                                <li className={cx('submenu-wrapper')}>
                                    {renderDragAndDrop(
                                        item.subMenu,
                                        'subMenu',
                                        item.id,
                                        `${currentIndex}.`, // Pass down hierarchy prefix
                                    )}
                                </li>
                            )}
                        </li>
                    );
                }}
            </DragAndDropList>
        </ul>
    );

    return (
        <div className={cx('chapters-wrapper')}>
            {renderDragAndDrop(treeData, 'chapter')}
        </div>
    );
}

export default RecursiveDragAndDrop;
