import React, { useEffect, useMemo, useCallback } from 'react';
import {
    matchRoutes,
    RouteObject,
    useLocation,
    useNavigate,
    useParams,
} from 'react-router-dom';

import classnames from 'classnames/bind';

import {
    useGetChapterByIdAdminLazy,
    useGetSectionByIdAdminLazy,
} from '@SERVICES';

import ROUTES from '@CONSTANTS/ROUTES.constant';

import Crumb from '@COMPONENTS/HEADERS/breadcrumbs/common/Crumb/Crumb.component';

import Searchbar from '@COMPONENTS/HEADERS/breadcrumbs/AdminBreadclumbs/components/Searchbar';
import styles from './AdminBreadcrumbs.module.scss';

const cx: CX = classnames.bind(styles);

type Breadcrumb = {
    id: number;
    title: string;
    routes: string[];
};

const breadcrumbs: Breadcrumb[] = [
    {
        id: 1,
        title: 'Documents List',
        routes: [
            ROUTES.admin.documents.path,
            ROUTES.admin.documents.id.path,
            ROUTES.admin.chapters.id.path,
            ROUTES.admin.sections.id.path,
        ],
    },
    {
        id: 2,
        title: 'Document',
        routes: [
            ROUTES.admin.documents.id.path,
            ROUTES.admin.chapters.id.path,
            ROUTES.admin.sections.id.path,
        ],
    },
    {
        id: 3,
        title: 'Chapter',
        routes: [ROUTES.admin.chapters.id.path, ROUTES.admin.sections.id.path],
    },
    {
        id: 4,
        title: 'Section',
        routes: [ROUTES.admin.sections.id.path],
    },
];

const routes: RouteObject[] = [
    { path: ROUTES.admin.documents.path },
    { path: ROUTES.admin.documents.id.path },
    { path: ROUTES.admin.chapters.id.path },
    { path: ROUTES.admin.sections.id.path },
];

type SectionParent = {
    id: number;
    name: string;
    parent: SectionParent | null;
};

const useSectionHierarchy = (
    sections: SectionParent | null,
    currentSection: [{ title: string; id: number }],
) => {
    const getSectionHierarchy = useCallback((section: SectionParent | null) => {
        const names = [];
        for (let current = section; current; current = current.parent) {
            names.push({ id: current.id, title: current.name });
        }
        return [...names.reverse(), ...currentSection];
    }, [currentSection]);

    return useMemo(
        () => getSectionHierarchy(sections),
        [sections, getSectionHierarchy],
    );
};

function AdminBreadcrumbs() {
    const [getChapter, { data: chapterData }] = useGetChapterByIdAdminLazy();
    const [getSection, { data: sectionData }] = useGetSectionByIdAdminLazy();

    const sectionParentNames = useSectionHierarchy(
        sectionData?.data.parent as SectionParent | null,
        [
            {
                title: sectionData?.data?.name || '',
                id: sectionData?.data?.id || 0,
            },
        ],
    );

    const location = useLocation();
    const navigate = useNavigate();
    const params = useParams();

    const [{ route }] = matchRoutes(routes, location) || [];

    const isDocumentsListPage = route.path === ROUTES.admin.documents.path;
    const isChapterPage = route.path === ROUTES.admin.chapters.id.path;
    const isSectionPage = route.path === ROUTES.admin.sections.id.path;

    useEffect(() => {
        const { id } = params;

        if (isChapterPage) {
            getChapter(+id!, true);
        } else if (isSectionPage) {
            getSection(+id!, true);
        }
    }, [isChapterPage, isSectionPage, getChapter, getSection, params]);

    function getSearchType() {
        const { path } = route;

        if (path === ROUTES.admin.documents.id.path) {
            return 'document';
        }

        if (path === ROUTES.admin.chapters.id.path) {
            return 'chapter';
        }

        if (path === ROUTES.admin.sections.id.path) {
            return 'section';
        }

        return null;
    }

    function navigateTo(crumb: Breadcrumb) {
        const crumbRoute = crumb.routes[0];

        const isDocumentsListCrumb = crumbRoute === ROUTES.admin.documents.path;
        const isDocumentCrumb = crumbRoute === ROUTES.admin.documents.id.path;
        const isChapterCrumb = crumbRoute === ROUTES.admin.chapters.id.path;

        if (isDocumentsListCrumb) {
            navigate(ROUTES.admin.documents.path);
        } else if (isDocumentCrumb) {
            if (isChapterPage) {
                const id = chapterData?.data?.document?.id;

                if (id) navigate(ROUTES.admin.documents.id.get(id));
            } else if (isSectionPage) {
                const id = sectionData?.data?.document?.id;

                if (id) navigate(ROUTES.admin.documents.id.get(id));
            }
        } else if (isChapterCrumb) {
            const id = sectionData?.data?.chapter?.id;

            if (id) navigate(ROUTES.admin.chapters.id.get(id));
        }
    }

    return (
        <div className={cx('admin-breadcrumbs')}>
            <div className="container max-constraint center">
                <div className={cx('content')}>
                    <div className={cx('crumbs-wrapper')}>
                        {breadcrumbs
                            .filter((crumb) => crumb.routes.some((r) => r === route.path))
                            .map((crumb, i, list) => {
                                const key = crumb.id;
                                const isActive = crumb.routes[0] === route.path;
                                const isLast = i === list.length - 1;

                                if (
                                    crumb.title === 'Section'
                                    && sectionParentNames.length > 0
                                ) {
                                    return sectionParentNames.map(
                                        (sec, index) => {
                                            const isActiveSec = sec.id === Number(params.id);
                                            const isLastSec = index
                                                === sectionParentNames.length - 1;

                                            return (
                                                <Crumb
                                                    key={sec.id}
                                                    name={sec.title}
                                                    isActive={
                                                        isActiveSec || isLastSec
                                                    }
                                                    isLast={isLastSec}
                                                    onClick={() => {
                                                        navigate(
                                                            ROUTES.admin.sections.id.get(
                                                                sec.id,
                                                            ),
                                                        );
                                                    }}
                                                />
                                            );
                                        },
                                    );
                                }

                                return (
                                    <Crumb
                                        key={key}
                                        name={crumb.title}
                                        isActive={isActive}
                                        isLast={isLast}
                                        onClick={() => {
                                            navigateTo(crumb);
                                        }}
                                    />
                                );
                            })}
                    </div>

                    {!isDocumentsListPage && (
                        <div className={cx('searchbar-wrapper')}>
                            <Searchbar
                                id={+params.id!}
                                searchType={getSearchType()}
                            />
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}

export default AdminBreadcrumbs;
