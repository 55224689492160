import React, { useCallback, useMemo, useState } from 'react';

import classnames from 'classnames/bind';

import DESIGN from '@CONSTANTS/DESIGN.constant';

import {
    AdminSingleChapterData,
    AdminSingleChapterMeta,
} from '@INTERFACES/admin-api/chapters';

import useNewSectionModal from '@HOOKS/store/modals/useNewSectionModal';
import useDocumentPermissions from '@HOOKS/useDocumentPermissions';

import IntroductionText from '@PAGES/admin/edit-chapter/components/IntroductionText';
import Section from '@PAGES/admin/edit-chapter/components/Section';
import SectionsStickyHeader from '@PAGES/admin/edit-chapter/components/SectionsStickyHeader';
import FloatingPublishButton from '@PAGES/admin/edit-chapter/components/FloatingPublishButton';
import IntroductionTextStickyHeader from '@PAGES/admin/edit-chapter/components/IntroductionTextStickyHeader';

import AddButton from '@COMPONENTS/COMMON/buttons/AddButton';
import PageHeader from '@PAGES/admin/edit-chapter/components/PageHeader';

import styles from './Content.module.scss';

const cx: CX = classnames.bind(styles);

const top = DESIGN['subheader-height'] + DESIGN['admin-breadcrumbs-height'];

const useGenerateIndices = (flatArray: any[], originalResponse: any[]) => {
    const buildIndex = useCallback(
        (
            items: any[],
            prefix: string,
            indexMap: { [key: string]: string },
        ): { [key: string]: string } => {
            let updatedIndexMap = { ...indexMap };

            items.forEach((item, idx) => {
                const currentIndex = prefix
                    ? `${prefix}.${idx + 1}`
                    : `${idx + 1}`;
                updatedIndexMap[item.id] = currentIndex;

                if (item.children && item.children.length) {
                    updatedIndexMap = buildIndex(
                        item.children,
                        currentIndex,
                        updatedIndexMap,
                    );
                }
            });

            return updatedIndexMap;
        },
        [],
    );

    return useMemo(() => {
        const indexMap: { [key: string]: string } = {};
        const finalIndexMap = buildIndex(originalResponse, '', indexMap);

        return flatArray.map((item) => ({
            id: item.id,
            name: item.name,
            index: finalIndexMap[item.id] || 'N/A',
        }));
    }, [flatArray, originalResponse, buildIndex]);
};

function Content(props: Props) {
    const {
        chapterData, chapterMeta, filteredChapterData,
    } = props;

    const { sections } = filteredChapterData || { sections: [] };

    const { canEditDocument } = useDocumentPermissions({
        documentOwnerId: chapterData.document.owner,
        permissions: chapterMeta.permissions,
    });

    const { actions: newSectionModalActions } = useNewSectionModal(() => true);

    const [isIntroductionOpen, setIntroductionOpen] = useState<boolean>(true);
    const [openSectionsIds, setOpenSectionsIds] = useState<Set<number>>(
        new Set(),
    );

    const flattenedSections = useMemo(() => {
        const flatten = (section: any) => {
            const result: any[] = [section];
            if (section.children) {
                section.children.forEach((child: any) => result.push(...flatten(child)));
            }
            return result;
        };

        return sections.reduce(
            (acc: any[], section) => acc.concat(flatten(section)),
            [],
        );
    }, [sections]);

    const indexedData = useGenerateIndices(flattenedSections, sections);

    const actionButton = (
        <FloatingPublishButton
            chapterData={chapterData}
            chapterMeta={chapterMeta}
            isIntroductionOpen={isIntroductionOpen}
            top={top}
        />
    );

    return (
        <div className={cx('content')}>
            <PageHeader
                chapterData={chapterData}
                chapterMeta={chapterMeta}
                actionButton={actionButton}
            />

            <div className={cx('accordion-wrap')}>
                <IntroductionTextStickyHeader
                    top={top}
                    isIntroductionOpen={isIntroductionOpen}
                    setIntroductionOpen={setIntroductionOpen}
                    chapterData={chapterData}
                    chapterMeta={chapterMeta}
                />

                {isIntroductionOpen && (
                    <IntroductionText
                        chapterData={filteredChapterData}
                        chapterMeta={chapterMeta}
                    />
                )}
            </div>

            <div className={cx('sections-wrapper')}>
                <SectionsStickyHeader
                    top={top}
                    chapterData={filteredChapterData}
                    chapterMeta={chapterMeta}
                    setOpenSectionsIds={setOpenSectionsIds}
                />
                <div className={cx('add-section-button', 'container')}>
                    <AddButton
                        locked={!canEditDocument}
                        title="New section"
                        fontSize={36}
                        onClick={() => {
                            newSectionModalActions.open({
                                documentId: filteredChapterData.document.id,
                                chapterId: filteredChapterData.id,
                                parent: null,
                            });
                        }}
                    />
                </div>
                {flattenedSections.length > 0 ? (
                    <div className={cx('sections', 'container')}>
                        {flattenedSections.map((section) => {
                            const key = section.id;
                            const index1 = indexedData.find(
                                (s) => s.id === section.id,
                            )?.index;

                            return (
                                <div
                                    key={key}
                                    className={cx('section-wrapper', {
                                        collapsed: !openSectionsIds.has(
                                            section.id,
                                        ),
                                    })}
                                >
                                    <Section
                                        index={index1 || '0'}
                                        chapterData={filteredChapterData}
                                        chapterMeta={chapterMeta}
                                        section={section}
                                        openSectionsIds={openSectionsIds}
                                        setOpenSectionsIds={setOpenSectionsIds}
                                    />
                                </div>
                            );
                        })}
                    </div>
                ) : (
                    <div className={cx('sections-not-found', 'container')}>
                        No sections
                    </div>
                )}
            </div>
        </div>
    );
}

type Props = {
    chapterData: AdminSingleChapterData;
    chapterMeta: AdminSingleChapterMeta;
    filteredChapterData: AdminSingleChapterData;
};

export default Content;
