import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import classnames from 'classnames/bind';

import {
    AdminSingleChapterMetaChapter,
    ChapterSection,
} from '@INTERFACES/admin-api/chapters';

import Accordions from '@COMPONENTS/COMMON/accordion/accordion';
import ChapterSideBarTreeView from '@COMPONENTS/SIDEBARS/SideBarTreeview';
import AddSectionButton from '@COMPONENTS/SIDEBARS/DocumentSidebar/AddSectionButton.component';

import ROUTES from '@CONSTANTS/ROUTES.constant';
import { DOCUMENT_PERMISSIONS } from '@CONSTANTS/PERMISSIONS.constant';

import useDocumentPermissions from '@HOOKS/useDocumentPermissions';

import { ReactComponent as BackArrowIcon } from '@ICONS/back-arrow-icon.svg';

import styles from './ChaptersView.module.scss';

const cx: CX = classnames.bind(styles);

function ChaptersView(props: Props) {
    const {
        chapters, sections, document, permission,
    } = props;

    const { id } = useParams();
    const navigate = useNavigate();

    const activeChapter = chapters.find(({ id: chId }) => chId === Number(id));

    const { canEditDocument } = useDocumentPermissions({
        documentOwnerId: document?.owner || 0,
        permissions: permission,
    });

    const addSubSectionButton = (
        btnText: string,
        chId: number,
        parentId: number | null = null,
    ) => (
        <AddSectionButton
            locked={!canEditDocument}
            title={btnText}
            payload={{
                documentId: document?.id || 0,
                chapterId: chId,
                parentId,
            }}
        />
    );

    const handleNavigate = (navigateId: number, navigateType = 'sections') => {
        navigate(
            (ROUTES.admin[navigateType as keyof typeof ROUTES.admin] as { [key: string]: any }).id.get(navigateId),
        );
    };

    const handleBackNavigation = () => {
        if (document?.id) {
            navigate(ROUTES.admin.documents.id.get(document.id));
        }
    };

    return (
        <div className={cx('chapters-view')}>
            <div className={cx('chapters-title')}>
                <BackArrowIcon onClick={handleBackNavigation} />
                <span>{activeChapter?.name}</span>
            </div>

            <div className={cx('documents-list')}>
                <Accordions
                    key={activeChapter?.id}
                    title={activeChapter?.name || ''}
                    detailsContain={(
                        <ChapterSideBarTreeView
                            list={sections}
                            chapterId={activeChapter?.id}
                            addSubSectionButton={addSubSectionButton}
                            handleNavigate={handleNavigate}
                        />
                    )}
                    defaultExpanded
                    classNames="first-accordion"
                />
            </div>
        </div>
    );
}

type Props = {
    chapters: AdminSingleChapterMetaChapter[];
    sections: ChapterSection[];
    document:
    | {
        id: number;
        name: string;
        owner: number;
    }
    | undefined;
    permission: DOCUMENT_PERMISSIONS[];
};

export default ChaptersView;
