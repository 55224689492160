import React, { useEffect, useMemo } from 'react';
import PropTypes, { InferProps } from 'prop-types';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { useNavigate } from 'react-router-dom';

import classnames from 'classnames/bind';

import { notify } from '@NOTIFICATION/Notificator';

import ROUTES from '@CONSTANTS/ROUTES.constant';
import { DOCUMENT_PERMISSIONS } from '@CONSTANTS/PERMISSIONS.constant';

import { AdminSingleDocumentChapter, useCopyChapter } from '@SERVICES';

import useDeleteChapterModal from '@HOOKS/store/modals/useDeleteChapterModal';
import useDocumentPermissions from '@HOOKS/useDocumentPermissions';

import TripleCardButtons from '@COMPONENTS/COMMON/buttons/TripleCardButtons';
import Warning from '@COMPONENTS/SHARED/Warning';

import ChapterSideBarTreeView from '@COMPONENTS/SIDEBARS/SideBarTreeview';
import AddSectionButton from '@COMPONENTS/SIDEBARS/DocumentSidebar/AddSectionButton.component';
import styles from './Chapter.module.scss';

const cx: CX = classnames.bind(styles);

function Chapter(props: Props) {
    const {
        documentId, documentOwnerId, chapter, index, permissions,
    } = props;

    const { canEditDocument } = useDocumentPermissions({
        documentOwnerId,
        permissions,
    });

    const [
        copyChapter,
        {
            isLoading: isCopyLoading,
            isSuccess: isCopySuccess,
            isError: isCopyError,
            error: copyError,
        },
    ] = useCopyChapter();

    const { actions: deleteChapterModalActions } = useDeleteChapterModal(
        () => true,
    );

    const navigate = useNavigate();

    const isPublished = useMemo(() => {
        const { introductionText, sections } = chapter;

        return (
            introductionText.isPublished
            && sections.every((section) => {
                const { baseText, variants } = section;

                return (
                    variants.every((variant) => variant.isPublished)
                    && baseText.isPublished
                );
            })
        );
    }, [chapter]);

    useEffect(() => {
        if (isCopySuccess) {
            notify.info('Chapter has been copied', {
                toastId: 'copy-chapter',
            });
        }
    }, [isCopySuccess]);

    useEffect(() => {
        if (isCopyError) {
            if ((copyError as FetchBaseQueryError).status === 403) {
                notify.error('Permission denied', {
                    toastId: 'copy-chapter-permission-error',
                });
            } else {
                notify.error('Something wrong!', {
                    toastId: 'copy-chapter-error',
                });
            }
        }
    }, [isCopyError, copyError]);

    const addSubSectionButton = (
        btnText: string,
        chId: number,
        parentId: number | null = null,
    ) => (
        <AddSectionButton
            locked={!canEditDocument}
            title={btnText}
            payload={{
                documentId,
                chapterId: chapter.id,
                parentId,
            }}
            className={
                btnText === 'Add Section'
                    ? 'lastButton'
                    : 'custom-subsection-button-wrapper'
            }
        />
    );

    return (
        <div className={cx('chapter')}>
            <div className={cx('chapter-info-wrapper')}>
                {!isPublished && <div className={cx('accent')} />}

                <div className={cx('header')}>
                    <div className={cx('chapter-number')}>
                        {`Chapter ${index + 1}`}
                    </div>
                    <TripleCardButtons
                        locked={{
                            edit: false,
                            copy: !canEditDocument,
                            delete: !canEditDocument,
                        }}
                        processing={{
                            edit: false,
                            copy: isCopyLoading,
                            delete: false,
                        }}
                        tooltips={{
                            edit: 'Edit chapter',
                            copy: 'Copy chapter',
                            delete: 'Delete chapter',
                        }}
                        onEdit={() => {
                            navigate(ROUTES.admin.chapters.id.get(chapter.id));
                        }}
                        onCopy={() => {
                            copyChapter({
                                documentId,
                                id: chapter.id,
                            });
                        }}
                        onDelete={() => {
                            deleteChapterModalActions.open({
                                id: chapter.id,
                                name: chapter.name,
                                documentId,
                            });
                        }}
                    />
                </div>

                <div className={cx('title')}>{chapter.name}</div>
            </div>
            <div className={cx('sections')}>
                {!chapter.introductionText.isPublished && (
                    <div className={cx('introduction-text-warning')}>
                        <Warning text="Introduction text" />
                    </div>
                )}

                <div className={cx('sections-title')}>Sections</div>

                {chapter.sections.length > 0 ? (
                    <div className={cx('sections-list')}>
                        <ChapterSideBarTreeView
                            list={chapter.sections}
                            chapterId={chapter.id}
                            addSubSectionButton={addSubSectionButton}
                            handleNavigate={(id: number) => {
                                navigate(ROUTES.admin.sections.id.get(id));
                            }}
                        />

                    </div>
                ) : (
                    <div className={cx('no-sections')}>No sections</div>
                )}
            </div>
        </div>
    );
}

Chapter.propTypes = {
    documentId: PropTypes.number.isRequired,
    documentOwnerId: PropTypes.number.isRequired,
    index: PropTypes.number.isRequired,
    permissions: PropTypes.instanceOf(Array<DOCUMENT_PERMISSIONS>).isRequired,
};

type Props = InferProps<typeof Chapter.propTypes> & {
    chapter: AdminSingleDocumentChapter;
};

export default Chapter;
