import React from 'react';
import PropTypes, { InferProps } from 'prop-types';

import classnames from 'classnames/bind';

import { AdminSingleChapterMeta, ChapterSection } from '@INTERFACES/admin-api/chapters';

import { ChapterSelectedOptions } from '@PAGES/admin/edit-chapter/types/ChapterSelectedOptions';

import FiltersView from '@COMPONENTS/SIDEBARS/EditChapterSidebar/components/FiltersView';

import ChaptersView from '@COMPONENTS/SIDEBARS/EditChapterSidebar/components/ChaptersView';
import styles from './EditChapterSidebar.module.scss';

const cx: CX = classnames.bind(styles);

function EditChapterSidebar(props: Props) {
    const {
        chapterMeta,
        chapterSection,
        searchValue,
        selectedOptions,
        isOnlyDrafts,
        selectedTagsIds,
        setSearchValue,
        setSelectedOptions,
        setOnlyDrafts,
        setSelectedTagsIds,
        view,
        document,
    } = props;

    return (
        <div className={cx('edit-chapter-sidebar')}>
            <div className={cx('container-wrapper')}>
                <div className={cx('wrapper')}>
                    {
                        view === 'filters'
                            ? (
                                <FiltersView
                                    chapterMeta={chapterMeta}
                                    searchValue={searchValue}
                                    selectedOptions={selectedOptions}
                                    isOnlyDrafts={isOnlyDrafts}
                                    setOnlyDrafts={setOnlyDrafts}
                                    selectedTagsIds={selectedTagsIds}
                                    setSearchValue={setSearchValue}
                                    setSelectedOptions={setSelectedOptions}
                                    setSelectedTagsIds={setSelectedTagsIds}
                                />
                            ) : (
                                <ChaptersView
                                    chapters={chapterMeta?.chapters || []}
                                    sections={chapterSection}
                                    document={document}
                                    permission={chapterMeta?.permissions || []}
                                />
                            )

                    }
                </div>
            </div>
        </div>
    );
}

EditChapterSidebar.propTypes = {
    searchValue: PropTypes.string.isRequired,
    isOnlyDrafts: PropTypes.bool.isRequired,
    selectedTagsIds: PropTypes.instanceOf(Set<number>).isRequired,
    setSearchValue: PropTypes.func.isRequired,
    setSelectedOptions: PropTypes.func.isRequired,
    setSelectedTagsIds: PropTypes.func.isRequired,
    setOnlyDrafts: PropTypes.func.isRequired,
    view: PropTypes.oneOf(['filters', 'chapters']).isRequired,
};

type Props = InferProps<typeof EditChapterSidebar.propTypes> & {
    chapterMeta: AdminSingleChapterMeta | null,
    chapterSection: ChapterSection[] | [],
    selectedOptions: ChapterSelectedOptions,
    document: {
        id: number;
        name: string;
        owner: number;
    } | undefined

};

export default EditChapterSidebar;
