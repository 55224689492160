import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import PropTypes, { InferProps } from 'prop-types';
import React, { useEffect, useState } from 'react';

import classnames from 'classnames/bind';

import { notify } from '@NOTIFICATION/Notificator';

import COLOR from '@CONSTANTS/COLOR.constant';

import { useReorderSections } from '@SERVICES';

import useOrderSectionsDrawer from '@HOOKS/store/drawers/useOrderSectionsDrawer';

import { OrderSectionsDrawerSection } from '@REDUCERS';

import BasicButton from '@COMPONENTS/COMMON/buttons/BasicButton';
import DragAndDropList from '@COMPONENTS/SHARED/DragAndDropList';

import RecursiveDragAndDrop from '@DRAWERS/OrderSectionsDrawer/RecursiveDragAndDrop';
import styles from './OrderSectionsDrawer.module.scss';

const cx: CX = classnames.bind(styles);

interface TreeNode {
    id: number;
    name: string;
    order: number;
    section?: TreeNode[];
    subSection?: TreeNode[];
    subMenu?: TreeNode[];
}

function OrderSectionsDrawer(props: Props) {
    const { onClose } = props;

    const {
        state: { data: drawerParams },
    } = useOrderSectionsDrawer();

    const [changeOrder, {
        isLoading, isSuccess, isError, error,
    }] = useReorderSections();

    const {
        documentId, chapterId, sections, canEditDocument,
    } = drawerParams!;

    const createTreeStructure = (data: any[]) => data.map((section) => ({
        id: section.id,
        name: section.name,
        order: section.order,
        subSection:
                section.children?.map((subSection: any) => ({
                    id: subSection.id,
                    name: subSection.name,
                    order: subSection.order,
                    subMenu:
                        subSection.children?.map((subMenu: any) => ({
                            id: subMenu.id,
                            name: subMenu.name,
                            order: subMenu.order,
                        })) || [],
                })) || [],
    }));

    const initialTreeData = createTreeStructure(sections);
    const [treeData, setTreeData] = useState<TreeNode[]>(initialTreeData);
    const [sectionsPayload, setSectionsPayload] = useState<
    { id: number; order: number }[]
    >([]);

    useEffect(() => {
        if (isSuccess) {
            notify.info('Sections order has been changed', {
                toastId: 'reorder-sections',
            });

            onClose();
        }
    }, [isSuccess, onClose]);

    useEffect(() => {
        if (isError) {
            if ((error as FetchBaseQueryError).status === 403) {
                notify.error('Permission denied', {
                    toastId: 'reorder-sections-permission-error',
                });
            } else {
                notify.error('Something wrong!', {
                    toastId: 'reorder-sections-error',
                });
            }
        }
    }, [isError, error]);

    const reorderList = (
        list: TreeNode[],
        reorderedItems: any[],
    ): TreeNode[] => {
        const itemMap = new Map(
            reorderedItems.map((item) => [item.id, item.order]),
        );
        return list
            .map((node) => ({
                ...node,
                order: itemMap.get(node.id) ?? node.order,
            }))
            .sort((a, b) => (a.order || 0) - (b.order || 0));
    };

    const getChildKey = (level: string): keyof TreeNode | null => {
        const levelMap: Record<string, keyof TreeNode> = {
            section: 'section',
            subSection: 'subSection',
            subMenu: 'subMenu',
        };
        return levelMap[level] || null;
    };

    const updateTree = (
        tree: TreeNode[],
        reorderedItems: TreeNode[],
        level: string,
        parentId?: number,
    ): TreeNode[] => tree.map((node) => {
        // If the current node is the parent, update its children
        if (parentId && node.id === parentId) {
            const childKey = getChildKey(level);
            if (childKey && Array.isArray(node[childKey])) {
                return {
                    ...node,
                    [childKey]: reorderList(
                        node[childKey] as TreeNode[],
                        reorderedItems,
                    ),
                };
            }
        }

        // Recursively update child nodes
        const childKeys: (keyof TreeNode)[] = [
            'section',
            'subSection',
            'subMenu',
        ];
        return {
            ...node,
            ...Object.fromEntries(
                childKeys.map((key) => [
                    key,
                    Array.isArray(node[key])
                        ? updateTree(
                            node[key] as TreeNode[],
                            reorderedItems,
                            level,
                            parentId,
                        )
                        : node[key],
                ]),
            ),
        };
    });

    const handleDragEnd = (items: any, level: string, parentId?: number) => {
        if (items.length === 2) {
            const [firstItem, secondItem] = items;
            const tempOrder = firstItem.order;
            firstItem.order = secondItem.order;
            secondItem.order = tempOrder;
        }
        let updatedTreeData = treeData;
        if (level === 'chapter') {
            updatedTreeData = items;
        } else {
            updatedTreeData = updateTree(treeData, items, level, parentId);
        }

        const newPayload = items.map((s: any) => ({
            id: s.id,
            order: s.order,
        }));

        setTreeData(updatedTreeData);
        setSectionsPayload((prevSections) => {
            const updatedSections = [...prevSections];
            newPayload.forEach((newItem: { id: number; order: number }) => {
                const existingIndex = updatedSections.findIndex(
                    (section) => section.id === newItem.id,
                );
                if (existingIndex !== -1) {
                    updatedSections[existingIndex].order = newItem.order;
                } else {
                    updatedSections.push(newItem);
                }
            });
            return updatedSections;
        });
    };

    return (
        <div className={cx('order-sections-drawer')}>
            <div className={cx('header')}>
                <div className={cx('title')}>Change sections order</div>
            </div>

            <div className={cx('sections-wrapper')}>
                <RecursiveDragAndDrop
                    treeData={treeData}
                    onDragEnd={handleDragEnd}
                />
            </div>

            <div className={cx('footer')}>
                <BasicButton
                    locked={!canEditDocument}
                    title="Save order"
                    isProcessing={isLoading}
                    style={{
                        backgroundColor: COLOR['app-green'],
                        height: 40,
                        fontSize: 14,
                    }}
                    onClick={() => {
                        changeOrder({
                            documentId,
                            chapterId,
                            sections: sectionsPayload,
                        });
                    }}
                />
            </div>
        </div>
    );
}

OrderSectionsDrawer.propTypes = {
    onClose: PropTypes.func.isRequired,
};

type Props = InferProps<typeof OrderSectionsDrawer.propTypes>;

export default OrderSectionsDrawer;
