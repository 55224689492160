import React from 'react';

import Accordions from '@COMPONENTS/COMMON/accordion/accordion';
import SectionSideBarTreeView from './SectionSidebarTreeview.component';
import '../DocumentSidebar/DocumentSidebar.module.scss';

function ChapterSideBarTreeView(props: any) {
    const {
        list, chapterId, addSubSectionButton, handleNavigate,
    } = props;

    return (
        <ul>
            {list?.map((item: any) => {
                const content = item?.children?.length ? (
                    <SectionSideBarTreeView
                        list={item.children}
                        addSubSectionButton={addSubSectionButton}
                        chapterId={chapterId}
                        handleNavigate={handleNavigate}
                        parentId={item.id}
                    />
                ) : (
                    addSubSectionButton('Add Sub-Section', chapterId, item.id)
                );
                return (
                    <Accordions
                        key={item.id}
                        title={item.name}
                        detailsContain={content}
                        handleClick={() => {
                            handleNavigate(item.id);
                        }}
                        classNames="outer-accordion"
                    />
                );
            })}
            {addSubSectionButton('Add Section', chapterId)}
        </ul>
    );
}

export default ChapterSideBarTreeView;
