import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';

import classnames from 'classnames/bind';

import { useGetChapterByIdAdmin } from '@SERVICES';

import { AdminSingleChapter } from '@INTERFACES/admin-api/chapters';

import DESIGN from '@CONSTANTS/DESIGN.constant';

import useDeleteSectionModal from '@HOOKS/store/modals/useDeleteSectionModal';
import usePublishChapterModal from '@HOOKS/store/modals/usePublishChapterModal';
import useChapterIntroductionTextHistoryModal from '@HOOKS/store/modals/useChapterIntroductionTextHistoryModal';
import usePublishChapterIntroductionTextModal from '@HOOKS/store/modals/usePublishChapterIntroductionTextModal';
import useNewSectionModal from '@HOOKS/store/modals/useNewSectionModal';
import useOrderSectionsDrawer from '@HOOKS/store/drawers/useOrderSectionsDrawer';
import useChapterNameModal from '@HOOKS/store/modals/useChapterNameModal';
import usePreviewChapterModal from '@HOOKS/store/modals/usePreviewChapterModal';
import useNewTagInChapterModal from '@HOOKS/store/modals/useNewTagInChapterModal';
import usePreviewChapterIntroductionTextModal from '@HOOKS/store/modals/usePreviewChapterIntroductionTextModal';
import usePreviewSectionModal from '@HOOKS/store/modals/usePreviewSectionModal';
import useSectionHistoryModal from '@HOOKS/store/modals/useSectionHistoryModal';

import { ChapterSelectedOptions } from '@PAGES/admin/edit-chapter/types/ChapterSelectedOptions';
import Content from '@PAGES/admin/edit-chapter/components/Content';
import getFilteredBySearchData from '@PAGES/admin/edit-chapter/helpers/getFilteredBySearchData';
import getFilteredByOptionsData from '@PAGES/admin/edit-chapter/helpers/getFilteredByOptionsData';
import getFilteredByDraftsOnlyData from '@PAGES/admin/edit-chapter/helpers/getFilteredByDraftsOnlyData';
import getFilteredByTagsData from '@PAGES/admin/edit-chapter/helpers/getFilteredByTagsData';

import ContentWithFullHeightSidebar from '@COMPONENTS/SHARED/ContentWithFullHeightSidebar';
import EditChapterSidebar from '@COMPONENTS/SIDEBARS/EditChapterSidebar';
import SidebarHeader from '@COMPONENTS/SIDEBARS/EditChapterSidebar/components/SidebarHeader';
import DataLoader from '@COMPONENTS/SHARED/DataLoader';

import styles from './EditChapter.module.scss';

const cx: CX = classnames.bind(styles);

const top = DESIGN['subheader-height'] + DESIGN['admin-breadcrumbs-height'];

const defaultData: AdminSingleChapter = {
    data: null,
    meta: null,
};

const initialSelectedOptions = {
    brand: null,
    region: null,
    area: null,
    country: null,
    propertyType: null,
};

function EditChapterPage() {
    const { id } = useParams();

    const {
        data: chapterData = defaultData, isLoading, isFetching, isError, error,
    } = useGetChapterByIdAdmin(+id!);

    const {
        actions: chapterNameModalActions,
    } = useChapterNameModal(() => true);

    const {
        actions: newSectionModalActions,
    } = useNewSectionModal(() => true);

    const {
        actions: publishChapterModalActions,
    } = usePublishChapterModal(() => true);

    const {
        actions: chapterIntroductionTextHistoryModalActions,
    } = useChapterIntroductionTextHistoryModal(() => true);

    const {
        actions: publishChapterIntroductionTextModalActions,
    } = usePublishChapterIntroductionTextModal(() => true);

    const {
        actions: deleteSectionModalActions,
    } = useDeleteSectionModal(() => true);

    const {
        actions: previewChapterModalActions,
    } = usePreviewChapterModal(() => true);

    const {
        actions: previewChapterIntroductionTextModalActions,
    } = usePreviewChapterIntroductionTextModal(() => true);

    const {
        actions: newTagInChapterModalActions,
    } = useNewTagInChapterModal(() => true);

    const {
        actions: previewSectionModalActions,
    } = usePreviewSectionModal(() => true);

    const {
        actions: sectionHistoryModalActions,
    } = useSectionHistoryModal(() => true);

    const {
        actions: orderSectionsDrawerActions,
    } = useOrderSectionsDrawer(() => true);

    const [searchValue, setSearchValue] = useState<string>('');

    const [debouncedSearchValue, setDebouncedSearchValue] = useState<string>('');

    const [selectedOptions, setSelectedOptions] = useState<ChapterSelectedOptions>({
        brand: null,
        region: null,
        area: null,
        country: null,
        propertyType: null,
    });

    const [isOnlyDrafts, setOnlyDrafts] = useState<boolean>(false);

    const [selectedTagsIds, setSelectedTagsIds] = useState<Set<number>>(new Set());

    const [view, setView] = useState<'filters' | 'chapters'>('chapters');

    // CLOSE MODALS AND DRAWERS
    useEffect(() => () => {
        chapterNameModalActions.close();
        newSectionModalActions.close();
        publishChapterModalActions.close();
        chapterIntroductionTextHistoryModalActions.close();
        publishChapterIntroductionTextModalActions.close();
        deleteSectionModalActions.close();
        previewChapterModalActions.close();
        previewChapterIntroductionTextModalActions.close();
        newTagInChapterModalActions.close();
        previewSectionModalActions.close();
        sectionHistoryModalActions.close();
        orderSectionsDrawerActions.close();
    }, [
        id,
        chapterNameModalActions,
        newSectionModalActions,
        publishChapterModalActions,
        chapterIntroductionTextHistoryModalActions,
        publishChapterIntroductionTextModalActions,
        deleteSectionModalActions,
        previewChapterModalActions,
        previewChapterIntroductionTextModalActions,
        newTagInChapterModalActions,
        previewSectionModalActions,
        sectionHistoryModalActions,
        orderSectionsDrawerActions,
    ]);

    // SCROLL TO TOP
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [id]);

    // RESET DATA
    useEffect(() => {
        setSearchValue('');
        setDebouncedSearchValue('');
        setSelectedOptions(initialSelectedOptions);
        setSelectedTagsIds(new Set());
    }, [id]);

    // DEBOUNCE INPUT
    useEffect(() => {
        const timer = setTimeout(() => {
            setDebouncedSearchValue(searchValue);
        }, 250);

        return () => {
            clearTimeout(timer);
        };
    }, [searchValue]);

    // SEARCH
    let filteredChapterData = useMemo(
        () => getFilteredBySearchData(chapterData, debouncedSearchValue),
        [chapterData, debouncedSearchValue],
    );

    // OPTIONS
    filteredChapterData = useMemo(
        () => getFilteredByOptionsData(filteredChapterData, selectedOptions),
        [filteredChapterData, selectedOptions],
    );

    // DRAFTS
    filteredChapterData = useMemo(
        () => getFilteredByDraftsOnlyData(filteredChapterData, isOnlyDrafts),
        [filteredChapterData, isOnlyDrafts],
    );

    // TAGS
    filteredChapterData = useMemo(
        () => getFilteredByTagsData(filteredChapterData, selectedTagsIds),
        [filteredChapterData, selectedTagsIds],
    );

    if (isError && (error as FetchBaseQueryError)?.status === 404) {
        return (
            <div className={cx('chapter-not-found', 'container')}>
                404 Chapter not found
            </div>
        );
    }

    return (
        <div className={cx('page')}>
            <div className="max-constraint center">
                <ContentWithFullHeightSidebar
                    top={top}
                    sidebarHeader={(
                        <SidebarHeader
                            view={view}
                            setView={setView}
                        />
                    )}
                    sidebar={(
                        <EditChapterSidebar
                            chapterMeta={filteredChapterData.meta}
                            chapterSection={filteredChapterData.data?.sections || []}
                            searchValue={searchValue}
                            selectedOptions={selectedOptions}
                            isOnlyDrafts={isOnlyDrafts}
                            selectedTagsIds={selectedTagsIds}
                            setSearchValue={setSearchValue}
                            setSelectedOptions={setSelectedOptions}
                            setSelectedTagsIds={setSelectedTagsIds}
                            setOnlyDrafts={setOnlyDrafts}
                            view={view}
                            document={chapterData.data?.document}
                        />
                    )}
                >
                    <DataLoader
                        isLoading={isLoading}
                        isFetching={isFetching}
                        isError={isError}
                        error={error}
                    >
                        <Content
                            key={chapterData.data?.id || 0}
                            chapterData={chapterData.data!}
                            chapterMeta={chapterData.meta!}
                            filteredChapterData={filteredChapterData.data!}
                        />
                    </DataLoader>
                </ContentWithFullHeightSidebar>
            </div>
        </div>
    );
}

export default EditChapterPage;
