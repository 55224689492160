import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { ADMIN_API_PREFIX, BASE_URL } from '@CONSTANTS/API.constant';
import userRepository from '@REPOSITORIES/local-repository/user-repository';
import {
    AdminSingleChapterData,
    AdminSingleChapterMeta,
    AdminSingleChapterMetaChapter,
    AdminSingleChapterDataSection,
} from '@INTERFACES/admin-api/chapters';

import {
    AdminSingleDocumentChapter,
    documentsAdminApi,
    formatVersion,
    sectionsAdminApi, tagsAdminApi,
    unpublishedAdminApi,
} from '@SERVICES';
import { DOCUMENT_PERMISSIONS } from '@CONSTANTS/PERMISSIONS.constant';
import invalidateData from '../helpers/invalidateData';
import { extractSectionsFilters } from '../helpers/extractSectionsFilters';

type SectionParent = {
    id: number;
    name: string;
    parent: SectionParent | null;
};

type Section = {
    id: number;
    name: string;
    order: number;
    is_mandatory: boolean;
    is_page_break: boolean;
    is_landscape: boolean;
    has_mandatory_conditions: boolean;
    base_text: {
        body: string;
        is_published: boolean;
        version: {
            id: number;
            description: string;
            body: string;
            created_at: string;
            author: {
                id: number;
                full_name: string;
                email: string;
            };
        } | null;
    };
    tags: {
        id: number;
        name: string;
    }[];
    variants: {
        id: number;
        name: string;
        body: string;
        is_default: boolean;
        is_published: boolean;
        version: {
            id: number;
            description: string;
            body: string;
            created_at: string;
            author: {
                id: number;
                full_name: string;
                email: string;
            };
        } | null;
        brands: {
            id: number;
            name: string;
        }[];
        regions: {
            id: number;
            name: string;
        }[];
        areas: {
            id: number;
            name: string;
        }[];
        countries: {
            id: number;
            name: string;
        }[];
        property_types: {
            id: number;
            name: string;
        }[];
        tags: {
            id: number;
            name: string;
        }[]
    }[];
    parent: SectionParent | null;
    children: Section | []
};

type ChaptersGetByIdAdminApiResponse = {
    data: {
        id: number;
        name: string;
        order: number;
        image: string | null;
        is_visible: boolean;
        is_mandatory: boolean;
        document: {
            id: number;
            name: string;
            owner: number;
        };
        introduction_text: {
            body: string;
            is_published: boolean;
            versions: {
                id: number;
                description: string;
                body: string;
                created_at: string;
                author: {
                    id: number;
                    full_name: string;
                    email: string;
                };
            }[];
        }
        tags: {
            id: number;
            name: string;
        }[];
        sections: Section[];
    };
    meta: {
        filters: {
            brands: {
                id: number;
                name: string;
            }[];
            regions: {
                id: number;
                name: string;
            }[];
            areas: {
                id: number;
                name: string;
                region: {
                    id: number;
                    name: string;
                }
            }[];
            countries: {
                id: number;
                name: string;
                region: {
                    id: number;
                    name: string;
                };
                area: {
                    id: number;
                    name: string;
                }
            }[];
            property_types: {
                id: number;
                name: string;
            }[];
        }
        tags: [],
        chapters: {
            id: number;
            name: string;
        }[];
        permissions: DOCUMENT_PERMISSIONS[];
    };
};

const SortById = (a: { id: number }, b: { id: number }) => a.id - b.id;

type ReturnType = {
    data: AdminSingleChapterData,
    meta: AdminSingleChapterMeta,
};

export type TagOption = {
    id: number;
    name: string;
};

export function extractSectionsTags(items: TagOption[]): TagOption[] {
    const tagsSet = new Set<number>();

    const tags: TagOption[] = [];

    items.forEach((item) => {
        if (item?.id && !tagsSet.has(item.id)) {
            tags.push(item);

            tagsSet.add(item.id);
        }
    });

    tags.sort(SortById);

    return tags;
}

function transformGetByIdResponse(raw: ChaptersGetByIdAdminApiResponse): ReturnType {
    type ApiSection = ChaptersGetByIdAdminApiResponse['data']['sections'][number];
    type Acc = AdminSingleChapterMeta['filters'][];

    const filters = extractSectionsFilters(raw.data.sections.reduce((acc: Acc, section: ApiSection) => {
        const { variants } = section;

        type ApiVariant = ApiSection['variants'][number];

        return [...acc, ...variants.reduce((innerAcc: Acc, variant: ApiVariant) => [...innerAcc, {
            brands: variant.brands,
            regions: variant.regions,
            areas: variant.areas,
            countries: variant.countries,
            propertyTypes: variant.property_types,
        }], [])];
    }, []));

    const tags = extractSectionsTags(raw.data.sections.reduce((acc: TagOption[], section: ApiSection) => {
        const { variants } = section;

        type ApiVariant = ApiSection['variants'][number];

        return [
            ...acc,
            ...section.tags,
            ...variants.reduce((innerAcc: TagOption[], variant: ApiVariant) => {
                const { tags: t } = variant;

                return [...innerAcc, ...t];
            }, []),
        ];
    }, []));

    const meta: AdminSingleChapterMeta = {
        filters,
        tags,
        chapters: raw.meta.chapters.map((chapter) => ({
            id: chapter.id,
            name: chapter.name,
        })),
        permissions: raw.meta.permissions,
    };

    const data = {
        id: raw.data.id,
        name: raw.data.name,
        document: raw.data.document,
        order: raw.data.order,
        image: raw.data.image,
        isVisible: raw.data.is_visible,
        isMandatory: raw.data.is_mandatory,
        introductionText: {
            body: raw.data.introduction_text.body || '',
            isPublished: raw.data.introduction_text.is_published,
            versions: raw.data.introduction_text.versions.map((version) => formatVersion(version)),
        },
        tags: raw.data.tags.map((tag) => ({
            id: tag.id,
            name: tag.name,
        })).sort(SortById),
        sections: raw.data.sections.map((section) => {
            const transformSection = (sec: ApiSection) : any => ({
                id: sec.id,
                name: sec.name,
                order: sec.order,
                isMandatory: sec.is_mandatory,
                isPageBreak: sec.is_page_break,
                isLandscape: sec.is_landscape,
                hasMandatoryConditions: sec.has_mandatory_conditions,
                baseText: {
                    body: sec.base_text.body || '',
                    isPublished: sec.base_text.is_published,
                    latestVersion: sec.base_text.version ? formatVersion(sec.base_text.version) : null,
                },
                tags: sec.tags.map((tag) => ({
                    id: tag.id,
                    name: tag.name,
                })).sort(SortById),
                variants: sec.variants.map((variant) => ({
                    id: variant.id,
                    name: variant.name,
                    body: variant.body || '',
                    isDefault: variant.is_default,
                    isPublished: variant.is_published,
                    latestVersion: variant.version ? formatVersion(variant.version) : null,
                    brands: variant.brands,
                    regions: variant.regions,
                    areas: variant.areas,
                    countries: variant.countries,
                    propertyTypes: variant.property_types,
                    tags: variant.tags.map((tag) => ({
                        id: tag.id,
                        name: tag.name,
                    })).sort(SortById),
                })),
                parent: sec.parent,
                children: Array.isArray(sec.children) ? sec.children.map(transformSection) : [],
            });

            return transformSection(section);
        }),
    };

    return {
        data,
        meta,
    };
}

type CreateParams = {
    documentId: number;
    name: string;
};

type CreateApiResponse = {
    id: number;
    name: string;
    order: number;
    image: string | null;
    is_visible: boolean;
    is_mandatory: boolean;
    document: number;
    body: string;
};

type UpdateParams = {
    id: number;
    name: string;
    body: string;
    isMandatory: boolean;
    image: Blob | string | null;
    documentId: number;
};

type DeleteParams = {
    id: number;
    documentId: number;
};

type CopyApiResponse = {
    id: number;
    name: string;
    order: number;
    is_visible: boolean;
    is_mandatory: boolean;
    introduction_text: {
        is_published: boolean;
    };
    sections: {
        id: number;
        name: string;
        base_text: {
            is_published: boolean;
        };
        variants: {
            id: number;
            is_published: boolean;
        }[];
    }[];
};

type CopyParams = {
    documentId: number;
    id: number;
};

type CreateAndAddTagParams = {
    documentId: number;
    id: number;
    tagName: string;
};

type AddTagApiResponse = {
    tag_id: number;
    tag_name: string;
};

type AddTagParams = {
    documentId: number;
    id: number;
    tagId: number;
};

type DeleteTagParams = {
    documentId: number;
    id: number;
    tagId: number;
};

export const chaptersAdminApi = createApi({
    reducerPath: 'chapters-admin-api',
    baseQuery: fetchBaseQuery({
        baseUrl: BASE_URL + ADMIN_API_PREFIX,
        prepareHeaders: (headers) => {
            const token = userRepository.getData();

            if (token) {
                headers.set('Authorization', `Bearer ${token}`);
            }

            return headers;
        },
    }),
    endpoints: (builder) => ({
        getById: builder.query<ReturnType, number>({
            query: (id: number) => `/chapters/${id}/`,
            transformResponse(raw: ChaptersGetByIdAdminApiResponse) {
                return transformGetByIdResponse(raw);
            },
        }),
        create: builder.mutation<CreateApiResponse, CreateParams>({
            query: (params) => {
                const formData = new FormData();

                formData.append('document', params.documentId.toString());
                formData.append('name', params.name);

                return {
                    url: '/chapters/',
                    method: 'POST',
                    body: formData,
                };
            },
            async onQueryStarted({ documentId }, { dispatch, queryFulfilled }) {
                try {
                    const queryData: { data: CreateApiResponse } = await queryFulfilled;

                    // INVALIDATE DATA
                    invalidateData(dispatch);

                    // ADD CHAPTER TO DOCUMENTS LIST
                    dispatch(
                        documentsAdminApi.util.updateQueryData('getAll', undefined, (draft) => draft.map((doc) => {
                            if (doc.data.id === documentId) {
                                return {
                                    data: {
                                        ...doc.data,
                                        chapters: [...doc.data.chapters, {
                                            id: queryData.data.id,
                                            name: queryData.data.name,
                                            order: queryData.data.order,
                                        }],
                                    },
                                    meta: doc.meta,
                                };
                            }

                            return doc;
                        })),
                    );

                    // ADD CHAPTER TO SINGLE DOCUMENT
                    const documentPatch = dispatch(
                        documentsAdminApi.util.updateQueryData('getById', documentId, (draft) => ({
                            data: {
                                ...draft.data,
                                chapters: [...draft.data.chapters, {
                                    id: queryData.data.id,
                                    name: queryData.data.name,
                                    order: queryData.data.order,
                                    isVisible: queryData.data.is_visible,
                                    introductionText: {
                                        isPublished: false,
                                    },
                                    sections: [],
                                }],
                            },
                            meta: draft.meta,
                        })),
                    );

                    const data = documentPatch.patches[0]?.value;

                    if (data) {
                        // ADD CHAPTER TO EVERY SINGLE CHAPTER META
                        data.data.chapters.forEach((chapter: AdminSingleDocumentChapter) => {
                            dispatch(
                                chaptersAdminApi.util.updateQueryData('getById', chapter.id, (draft) => ({
                                    data: draft.data,
                                    meta: {
                                        ...draft.meta,
                                        chapters: [...draft.meta.chapters, {
                                            id: queryData.data.id,
                                            name: queryData.data.name,
                                        }],
                                    },
                                })),
                            );
                        });
                    }
                } catch (e) {
                    //
                }
            },
        }),
        update: builder.mutation({
            query: (params: UpdateParams) => {
                const formData = new FormData();

                formData.append('name', params.name);
                formData.append('body', params.body || '');
                formData.append('is_mandatory', params.isMandatory.toString() || '');

                if (params.image || params.image === null) {
                    formData.append('image', params.image || '');
                }

                return {
                    url: `/chapters/${params.id}/`,
                    method: 'PATCH',
                    body: formData,
                };
            },
            async onQueryStarted({ id, documentId }, { dispatch, queryFulfilled }) {
                try {
                    const { data: queryData } = await queryFulfilled;

                    // INVALIDATE DATA
                    invalidateData(dispatch);

                    dispatch(
                        unpublishedAdminApi.util.resetApiState(),
                    );

                    // UPDATE CHAPTER IN DOCUMENTS LIST
                    dispatch(
                        documentsAdminApi.util.updateQueryData('getAll', undefined, (draft) => draft.map((doc) => {
                            if (doc.data.id === documentId) {
                                return {
                                    data: {
                                        ...doc.data,
                                        chapters: doc.data.chapters.map((chapter) => {
                                            if (chapter.id === id) {
                                                return {
                                                    ...chapter,
                                                    name: queryData.name,
                                                };
                                            }

                                            return chapter;
                                        }),
                                    },
                                    meta: doc.meta,
                                };
                            }

                            return doc;
                        })),
                    );

                    // UPDATE CHAPTER IN SINGLE DOCUMENT
                    dispatch(
                        documentsAdminApi.util.updateQueryData('getById', documentId, (draft) => ({
                            data: {
                                ...draft.data,
                                chapters: draft.data.chapters.map((chapter) => {
                                    if (chapter.id === id) {
                                        return {
                                            ...chapter,
                                            name: queryData.name,
                                            introductionText: {
                                                isPublished: queryData.is_published,
                                            },
                                        };
                                    }

                                    return chapter;
                                }),
                            },
                            meta: draft.meta,
                        })),
                    );

                    // UPDATE CHAPTER IN CURRENT SINGLE CHAPTER
                    const chapterPatch = dispatch(
                        chaptersAdminApi.util.updateQueryData('getById', id, (draft) => ({
                            data: {
                                ...draft.data,
                                name: queryData.name,
                                body: queryData.body || '',
                                isMandatory: queryData.is_mandatory,
                                introductionText: {
                                    ...draft.data.introductionText,
                                    body: queryData.body || '',
                                    isPublished: queryData.is_published,
                                },
                                image: queryData.image,
                            },
                            meta: draft.meta,
                        })),
                    );

                    const data = chapterPatch.patches[0]?.value;

                    if (data) {
                        // UPDATE CHAPTER IN EVERY SINGLE CHAPTER META
                        data.meta.chapters.forEach((chapter: AdminSingleChapterMetaChapter) => {
                            dispatch(
                                chaptersAdminApi.util.updateQueryData('getById', chapter.id, (draft) => ({
                                    data: draft.data,
                                    meta: {
                                        ...draft.meta,
                                        chapters: draft.meta.chapters.map((c) => {
                                            if (c.id === id) {
                                                return {
                                                    ...c,
                                                    name: queryData.name,
                                                };
                                            }

                                            return c;
                                        }),
                                    },
                                })),
                            );
                        });

                        // UPDATE CHAPTER IN CHAPTER SECTIONS
                        data.data.sections.forEach((section: AdminSingleChapterDataSection) => {
                            dispatch(
                                sectionsAdminApi.util.updateQueryData('getById', section.id, (draft) => ({
                                    data: {
                                        ...draft.data,
                                        chapter: {
                                            ...draft.data.chapter,
                                            name: queryData.name,
                                        },
                                    },
                                    meta: draft.meta,
                                })),
                            );
                        });
                    }
                } catch (e) {
                    //
                }
            },
        }),
        delete: builder.mutation(({
            query: (params: DeleteParams) => ({
                url: `/chapters/${params.id}/`,
                method: 'DELETE',
            }),
            async onQueryStarted({ id, documentId }, { dispatch, queryFulfilled }) {
                try {
                    await queryFulfilled;

                    // INVALIDATE DATA
                    invalidateData(dispatch);

                    dispatch(
                        unpublishedAdminApi.util.resetApiState(),
                    );

                    dispatch(
                        chaptersAdminApi.util.resetApiState(),
                    );

                    dispatch(
                        sectionsAdminApi.util.resetApiState(),
                    );

                    // DELETE CHAPTER FROM DOCUMENTS LIST
                    dispatch(
                        documentsAdminApi.util.updateQueryData('getAll', undefined, (draft) => draft.map((doc) => {
                            if (doc.data.id === documentId) {
                                return {
                                    data: {
                                        ...doc.data,
                                        chapters: doc.data.chapters.filter((chapter) => chapter.id !== id),
                                    },
                                    meta: doc.meta,
                                };
                            }

                            return doc;
                        })),
                    );

                    // DELETE CHAPTER FROM SINGLE DOCUMENT
                    const documentPatch = dispatch(
                        documentsAdminApi.util.updateQueryData('getById', documentId, (draft) => ({
                            data: {
                                ...draft.data,
                                chapters: draft.data.chapters.filter((c) => c.id !== id),
                            },
                            meta: draft.meta,
                        })),
                    );

                    const data = documentPatch.patches[0]?.value;

                    if (data) {
                        // DELETE CHAPTER FROM EVERY SINGLE CHAPTER META
                        data.data.chapters.forEach((chapter: AdminSingleChapterMetaChapter) => {
                            dispatch(
                                chaptersAdminApi.util.updateQueryData('getById', chapter.id, (draft) => ({
                                    data: draft.data,
                                    meta: {
                                        ...draft.meta,
                                        chapters: draft.meta.chapters.filter((c) => c.id !== id),
                                    },
                                })),
                            );
                        });
                    }
                } catch (e) {
                    //
                }
            },
        })),
        copy: builder.mutation<CopyApiResponse, CopyParams>({
            query: (params) => ({
                url: `/chapters/${params.id}/copy/`,
                method: 'POST',
            }),
            async onQueryStarted({ documentId }, { dispatch, queryFulfilled }) {
                try {
                    const { data: queryData } = await queryFulfilled;

                    // INVALIDATE DATA
                    invalidateData(dispatch);

                    dispatch(
                        unpublishedAdminApi.util.resetApiState(),
                    );

                    // COPY CHAPTER IN DOCUMENTS LIST
                    dispatch(
                        documentsAdminApi.util.updateQueryData('getAll', undefined, (draft) => draft.map((doc) => {
                            if (doc.data.id === documentId) {
                                return {
                                    data: {
                                        ...doc.data,
                                        chapters: [...doc.data.chapters, {
                                            id: queryData.id,
                                            name: queryData.name,
                                            order: queryData.order,
                                        }],
                                    },
                                    meta: doc.meta,
                                };
                            }

                            return doc;
                        })),
                    );

                    // COPY CHAPTER IN SINGLE DOCUMENT
                    const documentPatch = dispatch(
                        documentsAdminApi.util.updateQueryData('getById', documentId, (draft) => ({
                            data: {
                                ...draft.data,
                                chapters: [...draft.data.chapters, {
                                    id: queryData.id,
                                    name: queryData.name,
                                    order: queryData.order,
                                    isVisible: queryData.is_visible,
                                    introductionText: {
                                        isPublished: queryData.introduction_text.is_published,
                                    },
                                    sections: queryData.sections.map((section) => ({
                                        id: section.id,
                                        name: section.name,
                                        baseText: {
                                            isPublished: section.base_text.is_published,
                                        },
                                        variants: section.variants.map((variant) => ({
                                            id: variant.id,
                                            isPublished: variant.is_published,
                                        })),
                                        parent: null,
                                        children: [],
                                        order: queryData.order,
                                    })),
                                }],
                            },
                            meta: draft.meta,
                        })),
                    );

                    const data = documentPatch.patches[0]?.value;

                    if (data) {
                        // COPY CHAPTER IN EVERY SINGLE CHAPTER META
                        data.data.chapters.forEach((chapter: AdminSingleDocumentChapter) => {
                            dispatch(
                                chaptersAdminApi.util.updateQueryData('getById', chapter.id, (draft) => ({
                                    data: draft.data,
                                    meta: {
                                        ...draft.meta,
                                        chapters: [...draft.meta.chapters, {
                                            id: queryData.id,
                                            name: queryData.name,
                                        }],
                                    },
                                })),
                            );
                        });
                    }
                } catch (e) {
                    //
                }
            },
        }),
        createAndAddTag: builder.mutation<AddTagApiResponse, CreateAndAddTagParams>({
            query: (params) => {
                const formData = JSON.stringify({
                    tag_name: params.tagName,
                });

                return {
                    url: `/chapters/${params.id}/tags/`,
                    method: 'POST',
                    body: formData,
                };
            },
            async onQueryStarted(args, { dispatch, queryFulfilled }) {
                const { id, documentId } = args;

                try {
                    const { data: queryData } = await queryFulfilled;

                    // INVALIDATE DATA
                    invalidateData(dispatch);

                    // ADD TAG TO SINGLE CHAPTER
                    dispatch(
                        chaptersAdminApi.util.updateQueryData('getById', id, (draft) => ({
                            data: {
                                ...draft.data,
                                tags: [...draft.data.tags, {
                                    id: queryData.tag_id,
                                    name: queryData.tag_name,
                                }],
                            },
                            meta: draft.meta,
                        })),
                    );

                    // ADD TAG TO DOCUMENT
                    dispatch(
                        tagsAdminApi.util.updateQueryData('getAll', { documentId }, (draft) => ({
                            data: [...draft.data, {
                                id: queryData.tag_id,
                                name: queryData.tag_name,
                            }],
                        })),
                    );
                } catch (e) {
                    //
                }
            },
        }),
        addTag: builder.mutation<AddTagApiResponse, AddTagParams>({
            query: (params) => {
                const formData = JSON.stringify({
                    tag_id: params.tagId,
                });

                return {
                    url: `/chapters/${params.id}/tags/`,
                    method: 'PATCH',
                    body: formData,
                };
            },
            async onQueryStarted(args, { dispatch, queryFulfilled }) {
                const { id } = args;

                try {
                    const { data: queryData } = await queryFulfilled;

                    // INVALIDATE DATA
                    invalidateData(dispatch);

                    // ADD TAG TO SINGLE CHAPTER
                    dispatch(
                        chaptersAdminApi.util.updateQueryData('getById', id, (draft) => ({
                            data: {
                                ...draft.data,
                                tags: [...draft.data.tags, {
                                    id: queryData.tag_id,
                                    name: queryData.tag_name,
                                }].sort(SortById),
                            },
                            meta: draft.meta,
                        })),
                    );
                } catch (e) {
                    //
                }
            },
        }),
        deleteTag: builder.mutation<void, DeleteTagParams>({
            query: (params) => {
                const formData = JSON.stringify({
                    tag_id: params.tagId,
                });

                return {
                    url: `/chapters/${params.id}/tags/`,
                    method: 'DELETE',
                    body: formData,
                };
            },
            async onQueryStarted(args, { dispatch, queryFulfilled }) {
                const { id, tagId } = args;

                try {
                    await queryFulfilled;

                    // INVALIDATE DATA
                    invalidateData(dispatch);

                    // DELETE TAG FROM SINGLE CHAPTER
                    dispatch(
                        chaptersAdminApi.util.updateQueryData('getById', id, (draft) => ({
                            data: {
                                ...draft.data,
                                tags: draft.data.tags.filter((tag) => tag.id !== tagId),
                            },
                            meta: draft.meta,
                        })),
                    );
                } catch (e) {
                    //
                }
            },
        }),
    }),
});

export const {
    useGetByIdQuery: useGetChapterByIdAdmin,
    useLazyGetByIdQuery: useGetChapterByIdAdminLazy,
    useCreateMutation: useCreateChapter,
    useUpdateMutation: useUpdateChapter,
    useDeleteMutation: useDeleteChapter,
    useCopyMutation: useCopyChapter,
    useCreateAndAddTagMutation: useCreateAndAddTagToChapter,
    useAddTagMutation: useAddTagToChapter,
    useDeleteTagMutation: useDeleteTagFromChapter,
} = chaptersAdminApi;
