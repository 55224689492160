import React, { useMemo } from 'react';
import PropTypes, { InferProps } from 'prop-types';
import { useNavigate } from 'react-router-dom';

import classnames from 'classnames/bind';

import {
    AdminSingleSectionData,
    AdminSingleSectionMeta,
} from '@INTERFACES/admin-api/sections';

import ROUTES from '@CONSTANTS/ROUTES.constant';

import useDocumentPermissions from '@HOOKS/useDocumentPermissions';

import { ReactComponent as BackArrowIcon } from '@ICONS/back-arrow-icon.svg';

import SectionSideBarTreeView from '../SideBarTreeview/SectionSidebarTreeview.component';
import AddSectionButton from '../DocumentSidebar/AddSectionButton.component';

import styles from './EditSectionSidebar.module.scss';

const cx: CX = classnames.bind(styles);

const getInnerParentId = (obj: any) => obj?.parent?.id ?? obj?.id ?? null;

function EditSectionSidebar(props: Props) {
    const { sectionMeta, sectionData } = props;

    const currentSectionId = useMemo(() => (!sectionData?.parent ? sectionData?.id : getInnerParentId(sectionData?.parent)), [sectionData]);
    const currentSection = useMemo(() => sectionMeta?.sections.find((s) => s.id === currentSectionId), [sectionMeta, currentSectionId]);

    const navigate = useNavigate();

    const { canEditDocument } = useDocumentPermissions({
        documentOwnerId: sectionData?.document?.owner || 0,
        permissions: sectionMeta?.permissions || [],
    });

    const addSubSectionButton = (
        btnText: string,
        chId: number,
        parentId: number | null = null,
    ) => (
        <AddSectionButton
            locked={!canEditDocument}
            title={btnText}
            payload={{
                documentId: sectionData?.document?.id || 0,
                chapterId: chId,
                parentId,
                currentSection: sectionData?.id,
            }}
        />
    );

    const handleNavigate = (navigateId: number) => {
        navigate(ROUTES.admin.sections.id.get(navigateId));
    };

    const handleBackNavigation = () => {
        const { parent } = sectionData;
        if (!parent) {
            navigate(ROUTES.admin.chapters.id.get(sectionData.chapter.id));
        } else {
            navigate(ROUTES.admin.sections.id.get(parent.id));
        }
    };

    return (
        <div
            className={cx('edit-section-sidebar')}
        >
            <div className={cx('section-view')}>
                <div className={cx('section-title')}>
                    <BackArrowIcon onClick={handleBackNavigation} />
                    <span>
                        {' '}
                        {sectionData?.name}
                    </span>
                </div>
                <div className={cx('section-list')}>
                    <SectionSideBarTreeView
                        list={currentSection?.children}
                        addSubSectionButton={addSubSectionButton}
                        chapterId={sectionData?.chapter?.id || 0}
                        handleNavigate={handleNavigate}
                        parentId={sectionData?.id || 0}
                    />
                </div>

            </div>
        </div>
    );
}

EditSectionSidebar.propTypes = {
};

type Props = InferProps<typeof EditSectionSidebar.propTypes> & {
    sectionMeta: AdminSingleSectionMeta;
    sectionData: AdminSingleSectionData;
};

export default EditSectionSidebar;
